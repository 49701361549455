import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

// Initialize Firebase
let config = {
  apiKey: 'AIzaSyDs1zBH-iIfSStHzTwJIAuxhSijbQZ0C6w',
  authDomain: 'anglidata-1aa70.firebaseapp.com',
  projectId: 'anglidata-1aa70',
  storageBucket: 'anglidata-1aa70.appspot.com',
  messagingSenderId: '829829954137',
  appId: '1:829829954137:web:d872893753b9c2d09d8f48',
  measurementId: 'G-6BEX8RD016'
}

firebase.initializeApp(config)
firebase.analytics()

// utils
const db = firebase.firestore()
const storage = firebase.storage()
const FieldValue = firebase.firestore.FieldValue
const Timestamp = firebase.firestore.Timestamp
const auth = firebase.auth()

export {
  db,
  auth,
  FieldValue,
  Timestamp,
  storage
}
