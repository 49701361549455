// en, de, fr, pt
export default {
  es: {
    Dashboard: 'Dashboard',
    Warehouse: 'Almacén',
    Sales: 'Ventas',
    Purchases: 'Órdenes de compra',
    Payrolls: 'Planilla',
    Products: 'Productos',
    Brands: 'Marcas',
    Categories: 'Categorías',
    Warehouses: 'Almacenes',
    Inputs: 'Ingresos',
    Outputs: 'Salidas',
    Clients: 'Clientes',
    SalesInvoices: 'Facturas',
    Guides: 'Guías de remisión',
    Vehicles: 'Vehículos',
    Merchants: 'Mercaderistas',
    CreditNotes: 'Notas de crédito',
    Providers: 'Proveedores',
    PurchaseInvoices: 'Facturas',
    BillsOfExchange: 'Letras de cambio',
    Expenses: 'Gastos',
    DebitNotes: 'Notas de débito',
    Workers: 'Trabajadores',
    Config: 'Configuración',
    Users: 'Usuarios',
    ExchangeRate: 'Tipo de Cambio',
    ImportProducts: 'Importar Productos',
    ImportStock: 'Importar Stock',
    Estates: 'Propiedades',
    Transfers: 'Traslados',
    Invoices: 'Comprobantes',
    Settlements: 'Liquidaciones',
    Reports: 'Reportes',
    Movements: 'Movimientos',
    Requests: 'Solicitudes',
    Orders: 'Órdenes de compra',
    List: 'Listado',
    Addresses: 'Direcciones',
    Contacts: 'Contactos',
    Quotes: 'Cotizaciones',
    Credits: 'Notas de crédito',
    ProviderPurchases: 'Compras',
    Settings: 'Configuración',
    Queries: 'Consultas'
  }
}
